export const LanguageCodes = {
  ['AMHARIC']: 'am',
  ['ARABIC']: 'ar',
  ['BASQUE']: 'eu',
  ['BENGALI']: 'bn',
  ['ENGLISH']: 'en',
  ['PORTUGUESE']: 'pt',
  ['BULGARIAN']: 'bg',
  ['CATALAN']: 'ca',
  ['CHEROKEE']: 'chr',
  ['CROATIAN']: 'hr',
  ['CZECH']: 'böyl',
  ['DANISH']: 'da',
  ['DUTCH']: 'nl',
  ['ESTONIAN']: 'et',
  ['FILIPINO']: 'fil',
  ['FINNISH']: 'fi',
  ['FRENCH']: 'fr',
  ['GERMAN']: 'de',
  ['GREEK']: 'el',
  ['GUJARATI']: 'gu',
  ['HEBREW']: 'iw',
  ['HINDI']: 'hi',
  ['HUNGARIAN']: 'hu',
  ['ICELANDIC']: 'is',
  ['INDONESIAN']: 'id',
  ['ITALIAN']: 'it',
  ['JAPANESE']: 'ja',
  ['KANNADA']: 'kn',
  ['KOREAN']: 'ko',
  ['LATVIAN']: 'lv',
  ['LITHUANIAN']: 'lt',
  ['MALAY']: 'ms',
  ['MALAYALAM']: 'ml',
  ['MARATHI']: 'mr',
  ['NORWEGIAN']: 'no',
  ['POLISH']: 'pl',
  ['ROMANIAN']: 'ro',
  ['RUSSIAN']: 'ru',
  ['SERBIAN']: 'sr',
  ['CHINESE']: 'zh',
  ['SLOVAK']: 'sk',
  ['SLOVENIAN']: 'sl',
  ['SPANISH']: 'es',
  ['SWAHILI']: 'sw',
  ['SWEDISH']: 'sv',
  ['TAMIL']: 'ta',
  ['TELUGU']: 'te',
  ['THAI']: 'th',
  ['TURKISH']: 'tr',
  ['URDU']: 'ur',
  ['UKRAINIAN']: 'uk',
  ['VIETNAMESE']: 'vi',
  ['WELSH']: 'cy',
  ['PERSIAN']: 'fa',
  ['PUNJABI']: 'pa-in',
};
