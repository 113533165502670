export type Language =
  | 'ENGLISH'
  | 'SPANISH'
  | 'GERMAN'
  | 'TURKISH'
  | 'FRENCH'
  | 'CHINESE'
  | 'HINDI'
  | 'ARABIC'
  | 'BENGALI'
  | 'PORTUGUESE'
  | 'RUSSIAN'
  | 'URDU'
  | 'INDONESIAN'
  | 'JAPANESE'
  | 'SWAHILI'
  | 'PUNJABI'
  | 'KOREAN'
  | 'VIETNAMESE'
  | 'ITALIAN'
  | 'PERSIAN';

export type LanguageOption = {
  label: string;
  value: Language;
};

export type TranslationsFields = {
  newMessageToast: string;
  defaultError: string;
  userDataSuccess: string;
  newMessageBtn: string;
  you: string;
  sendPlaceholder: string;
  learnMore: string;
  resolutionQuestion: string;
  helped: string;
  notHelped: string;
  collectUserDataLabel: string;
  emailPlaceholder: string;
  phonePlaceholder: string;
  fullNamePlaceholder: string;
  dummyAiMessage: string;
  dummyUserMessage: string;
  supportRequestedTitle: string;
  supportRequestedDescription: string;
  joinText: string;
  aiIsWriting?: string;
  speaking: string;
  turnBackBtn: string;
  closeMessengerBtn: string;
  newConversationBtn: string;
  conversationClosed: string;
};

// Existing translations:
const DEFAULT_ENGLISH: TranslationsFields = {
  newMessageToast: 'New message!',
  defaultError: 'Something went wrong',
  userDataSuccess: 'User data successfully saved',
  newMessageBtn: 'New message',
  you: 'You',
  sendPlaceholder: 'Send message',
  learnMore: 'Learn More',
  resolutionQuestion: 'This response helpful to you?',
  helped: 'That helped 👍',
  notHelped: "That didn't help 👎",
  collectUserDataLabel: 'Fill out the form to get updates.',
  emailPlaceholder: 'Email',
  phonePlaceholder: 'Phone',
  fullNamePlaceholder: 'Full Name',
  dummyAiMessage: 'AI message',
  dummyUserMessage: 'visitor message',
  supportRequestedTitle: 'Human Support Requested 🎉',
  supportRequestedDescription:
    'We will send your request to all available agents, you will receive answers as soon as possible from here.',
  joinText: 'is joined the conversation',
  aiIsWriting: 'AI is writing',
  speaking: 'Speaking',
  turnBackBtn: 'Turn back',
  closeMessengerBtn: 'Close',
  newConversationBtn: 'New conversation',
  conversationClosed: 'The conversation has been closed by the agent.',
};

const DEFAULT_SPANISH: TranslationsFields = {
  newMessageToast: 'Nuevo mensaje!',
  defaultError: 'Algo salió mal',
  userDataSuccess: 'Datos de usuario guardados correctamente',
  newMessageBtn: 'Nuevo mensaje',
  you: 'Usted',
  sendPlaceholder: 'Enviar mensaje',
  learnMore: 'Más información',
  resolutionQuestion: '¿Le ha sido útil esta respuesta?',
  helped: 'Eso ayudó 👍',
  notHelped: 'Eso no ayudó 👎',
  collectUserDataLabel: 'Rellene el formulario para recibir actualizaciones.',
  emailPlaceholder: 'Correo electrónico',
  phonePlaceholder: 'Teléfono',
  fullNamePlaceholder: 'Nombre completo',
  dummyAiMessage: 'Mensaje AI ficticio',
  dummyUserMessage: 'Mensaje de usuario ficticio',
  supportRequestedTitle: 'Soporte humano solicitado 🎉',
  supportRequestedDescription:
    'Enviaremos su solicitud a todos los agentes disponibles, recibirá respuestas lo antes posible desde aquí.',
  joinText: 'se unió a la conversación',
  aiIsWriting: 'AI está escribiendo',
  speaking: 'Hablando',
  turnBackBtn: 'Volver',
  closeMessengerBtn: 'Cerrar',
  newConversationBtn: 'Nueva conversación',
  conversationClosed: 'La conversación ha sido cerrada por el agente.',
};

const DEFAULT_GERMAN: TranslationsFields = {
  newMessageToast: 'Neue Nachricht!',
  defaultError: 'Etwas ist schief gelaufen',
  userDataSuccess: 'Daten des Benutzers erfolgreich gespeichert',
  newMessageBtn: 'Neue Nachricht',
  you: 'Sie',
  sendPlaceholder: 'Nachricht senden',
  learnMore: 'Mehr erfahren',
  resolutionQuestion: 'Ist diese Antwort hilfreich für Sie?',
  helped: 'Das hat geholfen 👍',
  notHelped: 'Das hat nicht geholfen 👎',
  collectUserDataLabel: 'Füllen Sie das Formular aus, um Updates zu erhalten.',
  emailPlaceholder: 'E-Mail',
  phonePlaceholder: 'Telefon',
  fullNamePlaceholder: 'Vollständiger Name',
  dummyAiMessage: 'Dummy AI-Nachricht',
  dummyUserMessage: 'Dummy-Benutzernachricht',
  supportRequestedTitle: 'Menschliche Unterstützung angefordert 🎉',
  supportRequestedDescription:
    'Wir werden Ihre Anfrage an alle verfügbaren Agenten senden, Sie erhalten so schnell wie möglich Antworten von hier.',
  joinText: 'hat sich dem Gespräch angeschlossen',
  aiIsWriting: 'AI schreibt',
  speaking: 'Sprechen',
  turnBackBtn: 'Zurück',
  closeMessengerBtn: 'Schließen',
  newConversationBtn: 'Neues Gespräch',
  conversationClosed: 'Das Gespräch wurde vom Agenten geschlossen.',
};

const DEFAULT_TURKISH: TranslationsFields = {
  newMessageToast: 'Yeni Mesaj!',
  defaultError: 'Bir şeyler ters gitti.',
  userDataSuccess: 'Kullanıcı verileri başarıyla kaydedildi.',
  newMessageBtn: 'Yeni Mesaj',
  you: 'Sen',
  sendPlaceholder: 'Mesaj Gönder',
  learnMore: 'Daha fazla bilgi',
  resolutionQuestion: 'Bu yanıt size yardımcı oldu mu?',
  helped: 'Yanıt yardımcı oldu 👍',
  notHelped: 'Yanıt yardımcı olmadı 👎',
  collectUserDataLabel: 'Güncellemeleri almak için formu doldurun.',
  emailPlaceholder: 'E-posta',
  phonePlaceholder: 'Telefon',
  fullNamePlaceholder: 'Tam Adınız',
  dummyAiMessage: 'Demo yapay zeka mesajı',
  dummyUserMessage: 'Demo kullanıcı mesajı',
  supportRequestedTitle: 'Destek Talep Edildi 🎉',
  supportRequestedDescription:
    'Talebinizi tüm mevcut temsilcilere göndereceğiz, buradan en kısa sürede cevaplar alacaksınız.',
  joinText: 'sohbete katıldı',
  aiIsWriting: 'AI yazıyor',
  speaking: 'Konuşuyor',
  turnBackBtn: 'Geri',
  closeMessengerBtn: 'Kapat',
  newConversationBtn: 'Yeni sohbet',
  conversationClosed: 'Temsilci, görüşmeyi sonlandırdı.',
};

const DEFAULT_FRENCH: TranslationsFields = {
  newMessageToast: 'Nouveau message!',
  defaultError: "Quelque chose s'est mal passé",
  userDataSuccess: 'Données utilisateur enregistrées avec succès',
  newMessageBtn: 'Nouveau message',
  you: 'Vous',
  sendPlaceholder: 'Envoyer un message',
  learnMore: 'En savoir plus',
  resolutionQuestion: 'Cette réponse vous a-t-elle aidé ?',
  helped: 'Cela a aidé 👍',
  notHelped: "Cela n'a pas aidé 👎",
  collectUserDataLabel:
    'Remplissez le formulaire pour recevoir des mises à jour.',
  emailPlaceholder: 'E-mail',
  phonePlaceholder: 'Téléphone',
  fullNamePlaceholder: 'Nom complet',
  dummyAiMessage: 'Message IA',
  dummyUserMessage: 'Message visiteur',
  supportRequestedTitle: 'Support humain demandé 🎉',
  supportRequestedDescription:
    'Nous enverrons votre demande à tous les agents disponibles, vous recevrez des réponses dès que possible ici.',
  joinText: 'a rejoint la conversation',
  aiIsWriting: "L'IA écrit",
  speaking: 'Parle',
  turnBackBtn: 'Retour',
  closeMessengerBtn: 'Fermer',
  newConversationBtn: 'Nouvelle conversation',
  conversationClosed: "La conversation a été fermée par l'agent.",
};

// NEW LANGUAGES ADDED BELOW

const DEFAULT_CHINESE: TranslationsFields = {
  newMessageToast: '新消息！',
  defaultError: '出了点问题',
  userDataSuccess: '用户数据已成功保存',
  newMessageBtn: '新消息',
  you: '您',
  sendPlaceholder: '发送消息',
  learnMore: '了解更多',
  resolutionQuestion: '此回答对您有帮助吗？',
  helped: '有帮助 👍',
  notHelped: '没有帮助 👎',
  collectUserDataLabel: '填写表格以获得更新。',
  emailPlaceholder: '电子邮箱',
  phonePlaceholder: '电话',
  fullNamePlaceholder: '姓名',
  dummyAiMessage: 'AI 消息',
  dummyUserMessage: '访客消息',
  supportRequestedTitle: '已请求人工支持 🎉',
  supportRequestedDescription:
    '我们会将您的请求发送给所有可用的客服人员，您将在这里尽快收到回复。',
  joinText: '已加入对话',
  aiIsWriting: 'AI 正在输入',
  speaking: '正在说话',
  turnBackBtn: '返回',
  closeMessengerBtn: '关闭',
  newConversationBtn: '新的对话',
  conversationClosed: '会话已被客服关闭。',
};

const DEFAULT_HINDI: TranslationsFields = {
  newMessageToast: 'नया संदेश!',
  defaultError: 'कुछ गलत हो गया',
  userDataSuccess: 'उपयोगकर्ता डेटा सफलतापूर्वक सहेजा गया',
  newMessageBtn: 'नया संदेश',
  you: 'आप',
  sendPlaceholder: 'संदेश भेजें',
  learnMore: 'और जानें',
  resolutionQuestion: 'क्या यह उत्तर आपके लिए सहायक था?',
  helped: 'इससे मदद मिली 👍',
  notHelped: 'इससे मदद नहीं मिली 👎',
  collectUserDataLabel: 'अपडेट प्राप्त करने के लिए फॉर्म भरें।',
  emailPlaceholder: 'ईमेल',
  phonePlaceholder: 'फ़ोन',
  fullNamePlaceholder: 'पूरा नाम',
  dummyAiMessage: 'AI संदेश',
  dummyUserMessage: 'आगंतुक संदेश',
  supportRequestedTitle: 'मानव सहायता अनुरोधित 🎉',
  supportRequestedDescription:
    'हम आपकी अनुरोध सभी उपलब्ध एजेंटों को भेजेंगे, आपको यहीं जल्द से जल्द उत्तर प्राप्त होंगे।',
  joinText: 'चर्चा में शामिल हो गया है',
  aiIsWriting: 'AI लिख रहा है',
  speaking: 'बोल रहा है',
  turnBackBtn: 'वापस जाएं',
  closeMessengerBtn: 'बंद करें',
  newConversationBtn: 'नई वार्तालाप',
  conversationClosed: 'एजेंट द्वारा वार्तालाप बंद कर दिया गया है।',
};

const DEFAULT_ARABIC: TranslationsFields = {
  newMessageToast: 'رسالة جديدة!',
  defaultError: 'حدث خطأ ما',
  userDataSuccess: 'تم حفظ بيانات المستخدم بنجاح',
  newMessageBtn: 'رسالة جديدة',
  you: 'أنت',
  sendPlaceholder: 'إرسال رسالة',
  learnMore: 'اعرف المزيد',
  resolutionQuestion: 'هل كانت هذه الإجابة مفيدة لك؟',
  helped: 'نعم، ساعدني 👍',
  notHelped: 'لا، لم يساعدني 👎',
  collectUserDataLabel: 'املأ النموذج للحصول على التحديثات.',
  emailPlaceholder: 'البريد الإلكتروني',
  phonePlaceholder: 'الهاتف',
  fullNamePlaceholder: 'الاسم الكامل',
  dummyAiMessage: 'رسالة ذكاء اصطناعي',
  dummyUserMessage: 'رسالة زائر',
  supportRequestedTitle: 'تم طلب الدعم البشري 🎉',
  supportRequestedDescription:
    'سنرسل طلبك إلى جميع الوكلاء المتاحين، ستتلقى الردود في أقرب وقت ممكن من هنا.',
  joinText: 'انضم إلى المحادثة',
  aiIsWriting: 'الذكاء الاصطناعي يكتب',
  speaking: 'يتحدث',
  turnBackBtn: 'ارجع',
  closeMessengerBtn: 'إغلاق',
  newConversationBtn: 'محادثة جديدة',
  conversationClosed: 'تم إغلاق المحادثة من قبل الوكيل.',
};

const DEFAULT_BENGALI: TranslationsFields = {
  newMessageToast: 'নতুন বার্তা!',
  defaultError: 'কিছু ভুল হয়েছে',
  userDataSuccess: 'ব্যবহারকারীর ডেটা সফলভাবে সংরক্ষণ করা হয়েছে',
  newMessageBtn: 'নতুন বার্তা',
  you: 'আপনি',
  sendPlaceholder: 'বার্তা পাঠান',
  learnMore: 'আরও জানুন',
  resolutionQuestion: 'এই উত্তর কি আপনার জন্য সহায়ক ছিল?',
  helped: 'হ্যাঁ, এটি সাহায্য করেছে 👍',
  notHelped: 'না, এটি সাহায্য করেনি 👎',
  collectUserDataLabel: 'আপডেট পেতে ফর্মটি পূরণ করুন।',
  emailPlaceholder: 'ইমেইল',
  phonePlaceholder: 'ফোন',
  fullNamePlaceholder: 'পূর্ণ নাম',
  dummyAiMessage: 'AI বার্তা',
  dummyUserMessage: 'অতিথি বার্তা',
  supportRequestedTitle: 'মানব সহায়তা অনুরোধ করা হয়েছে 🎉',
  supportRequestedDescription:
    'আমরা আপনার অনুরোধটি সমস্ত উপলব্ধ এজেন্টকে পাঠাব, আপনি এখান থেকেই যত শীঘ্র সম্ভব উত্তর পাবেন।',
  joinText: 'আলোচনায় যোগ দিয়েছেন',
  aiIsWriting: 'AI লিখছে',
  speaking: 'বলছে',
  turnBackBtn: 'ফিরে যান',
  closeMessengerBtn: 'বন্ধ করুন',
  newConversationBtn: 'নতুন আলোচনা',
  conversationClosed: 'এজেন্ট দ্বারা আলোচনা বন্ধ করা হয়েছে।',
};

const DEFAULT_PORTUGUESE: TranslationsFields = {
  newMessageToast: 'Nova mensagem!',
  defaultError: 'Algo deu errado',
  userDataSuccess: 'Dados do usuário salvos com sucesso',
  newMessageBtn: 'Nova mensagem',
  you: 'Você',
  sendPlaceholder: 'Enviar mensagem',
  learnMore: 'Saiba mais',
  resolutionQuestion: 'Esta resposta foi útil para você?',
  helped: 'Isso ajudou 👍',
  notHelped: 'Isso não ajudou 👎',
  collectUserDataLabel: 'Preencha o formulário para receber atualizações.',
  emailPlaceholder: 'E-mail',
  phonePlaceholder: 'Telefone',
  fullNamePlaceholder: 'Nome completo',
  dummyAiMessage: 'Mensagem de IA',
  dummyUserMessage: 'Mensagem do visitante',
  supportRequestedTitle: 'Suporte humano solicitado 🎉',
  supportRequestedDescription:
    'Enviaremos sua solicitação a todos os agentes disponíveis, você receberá respostas o mais rápido possível aqui.',
  joinText: 'entrou na conversa',
  aiIsWriting: 'IA está escrevendo',
  speaking: 'Falando',
  turnBackBtn: 'Voltar',
  closeMessengerBtn: 'Fechar',
  newConversationBtn: 'Nova conversa',
  conversationClosed: 'A conversa foi encerrada pelo agente.',
};

const DEFAULT_RUSSIAN: TranslationsFields = {
  newMessageToast: 'Новое сообщение!',
  defaultError: 'Что-то пошло не так',
  userDataSuccess: 'Данные пользователя успешно сохранены',
  newMessageBtn: 'Новое сообщение',
  you: 'Вы',
  sendPlaceholder: 'Отправить сообщение',
  learnMore: 'Узнать больше',
  resolutionQuestion: 'Был ли этот ответ полезен для вас?',
  helped: 'Да, помогло 👍',
  notHelped: 'Нет, не помогло 👎',
  collectUserDataLabel: 'Заполните форму, чтобы получать обновления.',
  emailPlaceholder: 'Электронная почта',
  phonePlaceholder: 'Телефон',
  fullNamePlaceholder: 'Полное имя',
  dummyAiMessage: 'Сообщение от ИИ',
  dummyUserMessage: 'Сообщение от посетителя',
  supportRequestedTitle: 'Требуется помощь человека 🎉',
  supportRequestedDescription:
    'Мы отправим ваш запрос всем доступным агентам, вы получите ответы здесь, как можно скорее.',
  joinText: 'присоединился к разговору',
  aiIsWriting: 'ИИ печатает',
  speaking: 'Говорит',
  turnBackBtn: 'Вернуться',
  closeMessengerBtn: 'Закрыть',
  newConversationBtn: 'Новый разговор',
  conversationClosed: 'Разговор был закрыт агентом.',
};

const DEFAULT_URDU: TranslationsFields = {
  newMessageToast: 'نیا پیغام!',
  defaultError: 'کچھ غلط ہو گیا',
  userDataSuccess: 'صارف کا ڈیٹا کامیابی سے محفوظ ہوگیا',
  newMessageBtn: 'نیا پیغام',
  you: 'آپ',
  sendPlaceholder: 'پیغام بھیجیں',
  learnMore: 'مزید جانیں',
  resolutionQuestion: 'کیا یہ جواب آپ کے لیے مددگار تھا؟',
  helped: 'یہ مددگار ثابت ہوا 👍',
  notHelped: 'یہ مددگار ثابت نہیں ہوا 👎',
  collectUserDataLabel: 'تازہ کاریوں کے لیے فارم بھریں۔',
  emailPlaceholder: 'ای میل',
  phonePlaceholder: 'فون',
  fullNamePlaceholder: 'پورا نام',
  dummyAiMessage: 'اے آئی پیغام',
  dummyUserMessage: 'وزیٹر پیغام',
  supportRequestedTitle: 'انسانی مدد کی درخواست کی گئی 🎉',
  supportRequestedDescription:
    'ہم آپ کی درخواست تمام دستیاب ایجنٹس کو بھیجیں گے، آپ کو جلد سے جلد یہاں جوابات موصول ہوں گے۔',
  joinText: 'گفتگو میں شامل ہو گیا ہے',
  aiIsWriting: 'اے آئی لکھ رہا ہے',
  speaking: 'بول رہا ہے',
  turnBackBtn: 'واپس جائیں',
  closeMessengerBtn: 'بند کریں',
  newConversationBtn: 'نئی گفتگو',
  conversationClosed: 'مکالمہ ایجنٹ کی طرف سے بند کر دیا گیا ہے۔',
};

const DEFAULT_INDONESIAN: TranslationsFields = {
  newMessageToast: 'Pesan baru!',
  defaultError: 'Terjadi kesalahan',
  userDataSuccess: 'Data pengguna berhasil disimpan',
  newMessageBtn: 'Pesan baru',
  you: 'Anda',
  sendPlaceholder: 'Kirim pesan',
  learnMore: 'Pelajari lebih lanjut',
  resolutionQuestion: 'Apakah jawaban ini membantu Anda?',
  helped: 'Itu membantu 👍',
  notHelped: 'Itu tidak membantu 👎',
  collectUserDataLabel: 'Isi formulir untuk mendapatkan pembaruan.',
  emailPlaceholder: 'Email',
  phonePlaceholder: 'Telepon',
  fullNamePlaceholder: 'Nama lengkap',
  dummyAiMessage: 'Pesan AI',
  dummyUserMessage: 'Pesan pengunjung',
  supportRequestedTitle: 'Bantuan manusia diminta 🎉',
  supportRequestedDescription:
    'Kami akan mengirim permintaan Anda ke semua agen yang tersedia, Anda akan menerima jawaban secepatnya di sini.',
  joinText: 'bergabung dalam percakapan',
  aiIsWriting: 'AI sedang menulis',
  speaking: 'Berbicara',
  turnBackBtn: 'Kembali',
  closeMessengerBtn: 'Tutup',
  newConversationBtn: 'Percakapan baru',
  conversationClosed: 'Percakapan telah ditutup oleh agen.',
};

const DEFAULT_JAPANESE: TranslationsFields = {
  newMessageToast: '新しいメッセージがあります！',
  defaultError: '問題が発生しました',
  userDataSuccess: 'ユーザーデータが正常に保存されました',
  newMessageBtn: '新しいメッセージ',
  you: 'あなた',
  sendPlaceholder: 'メッセージを送る',
  learnMore: 'もっと詳しく知る',
  resolutionQuestion: 'この回答は役に立ちましたか？',
  helped: '役に立ちました 👍',
  notHelped: '役に立ちませんでした 👎',
  collectUserDataLabel: '更新を受け取るにはフォームにご記入ください。',
  emailPlaceholder: 'メールアドレス',
  phonePlaceholder: '電話番号',
  fullNamePlaceholder: '氏名',
  dummyAiMessage: 'AIメッセージ',
  dummyUserMessage: '訪問者メッセージ',
  supportRequestedTitle: '人間のサポートが要求されました 🎉',
  supportRequestedDescription:
    'ご要望を利用可能なエージェント全員に送信します。ここでできるだけ早く回答を受け取ることができます。',
  joinText: '会話に参加しました',
  aiIsWriting: 'AIが入力中',
  speaking: '話しています',
  turnBackBtn: '戻る',
  closeMessengerBtn: '閉じる',
  newConversationBtn: '新しい会話',
  conversationClosed: 'エージェントによって会話が終了しました。',
};

const DEFAULT_SWAHILI: TranslationsFields = {
  newMessageToast: 'Ujumbe Mpya!',
  defaultError: 'Kuna kitu kimeenda mrama',
  userDataSuccess: 'Taarifa za mtumiaji zimehifadhiwa kwa mafanikio',
  newMessageBtn: 'Ujumbe Mpya',
  you: 'Wewe',
  sendPlaceholder: 'Tuma ujumbe',
  learnMore: 'Jifunze zaidi',
  resolutionQuestion: 'Je, jibu hili limekusaidia?',
  helped: 'Limenisaidia 👍',
  notHelped: 'Halijanisaidia 👎',
  collectUserDataLabel: 'Jaza fomu kupata masasisho.',
  emailPlaceholder: 'Barua pepe',
  phonePlaceholder: 'Simu',
  fullNamePlaceholder: 'Jina kamili',
  dummyAiMessage: 'Ujumbe wa AI',
  dummyUserMessage: 'Ujumbe wa mgeni',
  supportRequestedTitle: 'Usaidizi wa binadamu umeombwa 🎉',
  supportRequestedDescription:
    'Tutatuma ombi lako kwa mawakala wote waliopo, utapata majibu hapa haraka iwezekanavyo.',
  joinText: 'amejiunga na mazungumzo',
  aiIsWriting: 'AI inandika',
  speaking: 'Anazungumza',
  turnBackBtn: 'Rudi nyuma',
  closeMessengerBtn: 'Funga',
  newConversationBtn: 'Mazungumzo mapya',
  conversationClosed: 'Mazungumzo yamefungwa na wakala.',
};

const DEFAULT_PUNJABI: TranslationsFields = {
  newMessageToast: 'ਨਵਾਂ ਸੁਨੇਹਾ!',
  defaultError: 'ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ',
  userDataSuccess: 'ਉਪਭੋਗਤਾ ਡਾਟਾ ਸਫਲਤਾਪੂਰਵਕ ਸੰਭਾਲਿਆ ਗਿਆ',
  newMessageBtn: 'ਨਵਾਂ ਸੁਨੇਹਾ',
  you: 'ਤੁਸੀਂ',
  sendPlaceholder: 'ਸੁਨੇਹਾ ਭੇਜੋ',
  learnMore: 'ਹੋਰ ਜਾਣੋ',
  resolutionQuestion: 'ਕੀ ਇਹ ਜਵਾਬ ਤੁਹਾਡੇ ਲਈ ਲਾਭਦਾਇਕ ਸੀ?',
  helped: 'ਇਹ ਮਦਦਗਾਰ ਸੀ 👍',
  notHelped: 'ਇਹ ਮਦਦਗਾਰ ਨਹੀਂ ਸੀ 👎',
  collectUserDataLabel: 'ਅੱਪਡੇਟ ਹਾਸਲ ਕਰਨ ਲਈ ਫਾਰਮ ਭਰੋ।',
  emailPlaceholder: 'ਈਮੇਲ',
  phonePlaceholder: 'ਫੋਨ',
  fullNamePlaceholder: 'ਪੂਰਾ ਨਾਮ',
  dummyAiMessage: 'AI ਸੁਨੇਹਾ',
  dummyUserMessage: 'ਵਿਜ਼ਟਰ ਸੁਨੇਹਾ',
  supportRequestedTitle: 'ਮਾਨਵ ਸਹਾਇਤਾ ਲਈ ਅਰਜ਼ੀ ਕੀਤੀ ਗਈ 🎉',
  supportRequestedDescription:
    'ਅਸੀਂ ਤੁਹਾਡੀ ਬੇਨਤੀ ਨੂੰ ਸਾਰੇ ਉਪਲਬਧ ਏਜੰਟਾ ਨੂੰ ਭੇਜਾਂਗੇ, ਤੁਹਾਨੂੰ ਇੱਥੇ ਜਲਦੀ ਤੋਂ ਜਲਦੀ ਜਵਾਬ ਮਿਲਣਗੇ।',
  joinText: 'ਗੱਲਬਾਤ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋ ਗਿਆ ਹੈ',
  aiIsWriting: 'AI ਲਿਖ ਰਿਹਾ ਹੈ',
  speaking: 'ਬੋਲ ਰਿਹਾ ਹੈ',
  turnBackBtn: 'ਵਾਪਸ ਜਾਓ',
  closeMessengerBtn: 'ਬੰਦ ਕਰੋ',
  newConversationBtn: 'ਨਵੀਂ ਗੱਲਬਾਤ',
  conversationClosed: 'ਏਜੰਟ ਦੁਆਰਾ ਗੱਲਬਾਤ ਨੂੰ ਬੰਦ ਕੀਤਾ ਗਿਆ ਹੈ।',
};

const DEFAULT_KOREAN: TranslationsFields = {
  newMessageToast: '새 메시지!',
  defaultError: '문제가 발생했습니다',
  userDataSuccess: '사용자 데이터가 성공적으로 저장되었습니다',
  newMessageBtn: '새 메시지',
  you: '당신',
  sendPlaceholder: '메시지 보내기',
  learnMore: '자세히 알아보기',
  resolutionQuestion: '이 응답이 도움이 되셨나요?',
  helped: '도움이 됐어요 👍',
  notHelped: '도움이 안 됐어요 👎',
  collectUserDataLabel: '업데이트를 받으려면 양식을 작성하세요.',
  emailPlaceholder: '이메일',
  phonePlaceholder: '전화번호',
  fullNamePlaceholder: '이름',
  dummyAiMessage: 'AI 메시지',
  dummyUserMessage: '방문자 메시지',
  supportRequestedTitle: '상담원 지원 요청됨 🎉',
  supportRequestedDescription:
    '귀하의 요청을 사용 가능한 모든 상담원에게 전송하겠습니다. 여기에서 가능한 한 빨리 답변을 받으실 수 있습니다.',
  joinText: '대화에 참여했습니다',
  aiIsWriting: 'AI가 작성 중',
  speaking: '말하는 중',
  turnBackBtn: '돌아가기',
  closeMessengerBtn: '닫기',
  newConversationBtn: '새로운 대화',
  conversationClosed: '상담원에 의해 대화가 종료되었습니다.',
};

const DEFAULT_VIETNAMESE: TranslationsFields = {
  newMessageToast: 'Tin nhắn mới!',
  defaultError: 'Đã xảy ra lỗi',
  userDataSuccess: 'Dữ liệu người dùng đã được lưu thành công',
  newMessageBtn: 'Tin nhắn mới',
  you: 'Bạn',
  sendPlaceholder: 'Gửi tin nhắn',
  learnMore: 'Tìm hiểu thêm',
  resolutionQuestion: 'Câu trả lời này có hữu ích cho bạn không?',
  helped: 'Có, rất hữu ích 👍',
  notHelped: 'Không hữu ích 👎',
  collectUserDataLabel: 'Điền vào biểu mẫu để nhận bản cập nhật.',
  emailPlaceholder: 'Email',
  phonePlaceholder: 'Số điện thoại',
  fullNamePlaceholder: 'Họ và tên',
  dummyAiMessage: 'Tin nhắn AI',
  dummyUserMessage: 'Tin nhắn khách',
  supportRequestedTitle: 'Yêu cầu hỗ trợ trực tiếp 🎉',
  supportRequestedDescription:
    'Chúng tôi sẽ gửi yêu cầu của bạn đến tất cả các nhân viên khả dụng, bạn sẽ nhận được phản hồi sớm nhất có thể tại đây.',
  joinText: 'đã tham gia cuộc trò chuyện',
  aiIsWriting: 'AI đang soạn thảo',
  speaking: 'Đang nói',
  turnBackBtn: 'Quay lại',
  closeMessengerBtn: 'Đóng',
  newConversationBtn: 'Cuộc trò chuyện mới',
  conversationClosed: 'Cuộc trò chuyện đã được kết thúc bởi nhân viên.',
};

const DEFAULT_ITALIAN: TranslationsFields = {
  newMessageToast: 'Nuovo messaggio!',
  defaultError: 'Si è verificato un errore',
  userDataSuccess: 'Dati utente salvati con successo',
  newMessageBtn: 'Nuovo messaggio',
  you: 'Tu',
  sendPlaceholder: 'Invia messaggio',
  learnMore: 'Per saperne di più',
  resolutionQuestion: 'Questa risposta è stata utile per te?',
  helped: 'È stato utile 👍',
  notHelped: 'Non è stato utile 👎',
  collectUserDataLabel: 'Compila il modulo per ricevere aggiornamenti.',
  emailPlaceholder: 'Email',
  phonePlaceholder: 'Telefono',
  fullNamePlaceholder: 'Nome completo',
  dummyAiMessage: 'Messaggio AI',
  dummyUserMessage: 'Messaggio del visitatore',
  supportRequestedTitle: 'Supporto umano richiesto 🎉',
  supportRequestedDescription:
    'Invieremo la tua richiesta a tutti gli agenti disponibili, riceverai risposte il prima possibile da qui.',
  joinText: 'si è unito alla conversazione',
  aiIsWriting: "L'IA sta scrivendo",
  speaking: 'Sta parlando',
  turnBackBtn: 'Torna indietro',
  closeMessengerBtn: 'Chiudi',
  newConversationBtn: 'Nuova conversazione',
  conversationClosed: "La conversazione è stata chiusa dall'agente.",
};

const DEFAULT_PERSIAN: TranslationsFields = {
  newMessageToast: 'پیام جدید!',
  defaultError: 'مشکلی پیش آمده است',
  userDataSuccess: 'اطلاعات کاربر با موفقیت ذخیره شد',
  newMessageBtn: 'پیام جدید',
  you: 'شما',
  sendPlaceholder: 'ارسال پیام',
  learnMore: 'بیشتر بدانید',
  resolutionQuestion: 'آیا این پاسخ برای شما مفید بود؟',
  helped: 'بله، کمک کرد 👍',
  notHelped: 'خیر، کمکی نکرد 👎',
  collectUserDataLabel: 'برای دریافت به‌روزرسانی‌ها فرم را پر کنید.',
  emailPlaceholder: 'ایمیل',
  phonePlaceholder: 'تلفن',
  fullNamePlaceholder: 'نام کامل',
  dummyAiMessage: 'پیام هوش مصنوعی',
  dummyUserMessage: 'پیام بازدیدکننده',
  supportRequestedTitle: 'درخواست پشتیبانی انسانی 🎉',
  supportRequestedDescription:
    'ما درخواست شما را برای همه‌ی نمایندگان موجود ارسال می‌کنیم. به زودی پاسخ‌ها را از همینجا دریافت خواهید کرد.',
  joinText: 'به گفتگو پیوست',
  aiIsWriting: 'هوش مصنوعی در حال نوشتن است',
  speaking: 'در حال صحبت',
  turnBackBtn: 'بازگشت',
  closeMessengerBtn: 'بستن',
  newConversationBtn: 'گفتگوی جدید',
  conversationClosed: 'گفتگو توسط نماینده بسته شده است.',
};

// Collect them all in DEFAULT_FIELDS
const DEFAULT_FIELDS: { [key in Language]: TranslationsFields } = {
  ENGLISH: DEFAULT_ENGLISH,
  SPANISH: DEFAULT_SPANISH,
  GERMAN: DEFAULT_GERMAN,
  TURKISH: DEFAULT_TURKISH,
  FRENCH: DEFAULT_FRENCH,
  CHINESE: DEFAULT_CHINESE,
  HINDI: DEFAULT_HINDI,
  ARABIC: DEFAULT_ARABIC,
  BENGALI: DEFAULT_BENGALI,
  PORTUGUESE: DEFAULT_PORTUGUESE,
  RUSSIAN: DEFAULT_RUSSIAN,
  URDU: DEFAULT_URDU,
  INDONESIAN: DEFAULT_INDONESIAN,
  JAPANESE: DEFAULT_JAPANESE,
  SWAHILI: DEFAULT_SWAHILI,
  PUNJABI: DEFAULT_PUNJABI,
  KOREAN: DEFAULT_KOREAN,
  VIETNAMESE: DEFAULT_VIETNAMESE,
  ITALIAN: DEFAULT_ITALIAN,
  PERSIAN: DEFAULT_PERSIAN,
};

// Extend your language options as needed
const LANGUAGE_OPTIONS: LanguageOption[] = [
  { label: 'Turkish', value: 'TURKISH' },
  { label: 'English', value: 'ENGLISH' },
  { label: 'German', value: 'GERMAN' },
  { label: 'French', value: 'FRENCH' },
  { label: 'Spanish', value: 'SPANISH' },

  // Add new languages here as desired:
  { label: 'Chinese (Mandarin)', value: 'CHINESE' },
  { label: 'Hindi', value: 'HINDI' },
  { label: 'Arabic', value: 'ARABIC' },
  { label: 'Bengali', value: 'BENGALI' },
  { label: 'Portuguese', value: 'PORTUGUESE' },
  { label: 'Russian', value: 'RUSSIAN' },
  { label: 'Urdu', value: 'URDU' },
  { label: 'Indonesian', value: 'INDONESIAN' },
  { label: 'Japanese', value: 'JAPANESE' },
  { label: 'Swahili', value: 'SWAHILI' },
  { label: 'Punjabi', value: 'PUNJABI' },
  { label: 'Korean', value: 'KOREAN' },
  { label: 'Vietnamese', value: 'VIETNAMESE' },
  { label: 'Italian', value: 'ITALIAN' },
  { label: 'Persian (Farsi)', value: 'PERSIAN' },
];

// Extend the browser language detection mapping if needed
const BROWSER_LANGUAGE_TO_LANGUAGE: { [key in Language]: string[] } = {
  ENGLISH: ['en', 'en-US', 'en-GB'],
  SPANISH: ['es', 'es-ES', 'es-419'],
  GERMAN: ['de', 'de-DE'],
  TURKISH: ['tr', 'tr-TR'],
  FRENCH: ['fr', 'fr-FR', 'fr-CA'],

  // New mappings:
  CHINESE: ['zh', 'zh-CN', 'zh-SG', 'zh-TW'],
  HINDI: ['hi', 'hi-IN'],
  ARABIC: ['ar', 'ar-SA', 'ar-EG'],
  BENGALI: ['bn', 'bn-BD', 'bn-IN'],
  PORTUGUESE: ['pt', 'pt-BR', 'pt-PT'],
  RUSSIAN: ['ru', 'ru-RU'],
  URDU: ['ur', 'ur-PK', 'ur-IN'],
  INDONESIAN: ['id', 'id-ID'],
  JAPANESE: ['ja', 'ja-JP'],
  SWAHILI: ['sw', 'sw-KE', 'sw-TZ'],
  PUNJABI: ['pa', 'pa-IN', 'pa-PK'],
  KOREAN: ['ko', 'ko-KR'],
  VIETNAMESE: ['vi', 'vi-VN'],
  ITALIAN: ['it', 'it-IT'],
  PERSIAN: ['fa', 'fa-IR'],
};

export {
  DEFAULT_ENGLISH,
  DEFAULT_SPANISH,
  DEFAULT_GERMAN,
  DEFAULT_TURKISH,
  DEFAULT_FRENCH,
  DEFAULT_CHINESE,
  DEFAULT_HINDI,
  DEFAULT_ARABIC,
  DEFAULT_BENGALI,
  DEFAULT_PORTUGUESE,
  DEFAULT_RUSSIAN,
  DEFAULT_URDU,
  DEFAULT_INDONESIAN,
  DEFAULT_JAPANESE,
  DEFAULT_SWAHILI,
  DEFAULT_PUNJABI,
  DEFAULT_KOREAN,
  DEFAULT_VIETNAMESE,
  DEFAULT_ITALIAN,
  DEFAULT_PERSIAN,
  DEFAULT_FIELDS,
  LANGUAGE_OPTIONS,
  BROWSER_LANGUAGE_TO_LANGUAGE,
};
